<style scoped lang="less">
  .top-menu {
    background-color: #FFF;
    display: flex;
    align-items: center;
    overflow: auto;
    flex-wrap: wrap;
    padding: 0 10px;
    box-sizing: border-box;
    height: 50px;
    flex-shrink: 0;
    .menu {
      font-size: 16px;
      color: #000;
      line-height: 50px;
      position: relative;
      padding: 0 5px;
      cursor: pointer;
      white-space: pre;
      word-break: keep-all;
      transition: all .3s;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: all .3s;
        content: '';
        height: 3px;
        width: 100%;
        display: inline-block;
      }
      &.active {
        &::after {
          opacity: 1;
        }
      }
    }
  }
</style>

<style lang="less" scoped>
.pink-theme {
  .top-menu {
    .menu {
      &.active {
        color: #F4628F;
        &:hover {color: #F4628F;}
      }
      &:hover { color: #F4628F; }
      &::after {
        background-color: #F4628F;
      }
    }
  }
}
.blue-theme, .dark-theme {
  .top-menu {
    .menu {
      &.active {
        color: #6B75CA;
        &:hover {color: #6B75CA;}
      }
      &:hover { color: #6B75CA; }
      &::after {
        background-color: #6B75CA;
      }
    }
  }
}

.yellow-theme {
  .top-menu {
    .menu {
      &.active {
        color: #6C573C;
        &:hover {color: #6C573C;}
      }
      &:hover { color: #6C573C; }
      &::after {
        background-color: #6C573C;
      }
    }
  }
}
</style>

<template>
  <div class="top-menu">
    <div
      class="menu"
      @click="clickMenu(menu)"
      :class="{ active: menu.url === activeName }"
      v-for="(menu, i) in menus"
      :key="menu.url + i">
      {{menu.name}}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  computed: {
    activeName () {
      return this.$store.state.route.name
    },
    menus () {
      return this.$store.getters.topMenus
    }
  },
  methods: {
    clickMenu (item) {
      this.$store.dispatch('setCurrentMenu', {menu: item})
    }
  }
}
</script>
