export const testPassword = (password) => {
  let reData = 'ok'
  if (typeof password !== 'string') {
    reData = 'noString'
  } else if (password.length < 8) {
    reData = 'noLong'
  } else if (!/[a-z]/.test(password)) {
    reData = 'noabc'
  } else if (!/[A-Z]/.test(password)) {
    reData = 'noABC'
  } else if (!/[0-9]/.test(password)) {
    reData = 'noNum'
  }/* else {
    reData = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【_】‘；：”“'。，、？]").test(password) ? 'ok' : 'noChart'
  }*/
  return reData
}