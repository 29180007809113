<style scoped lang="less">
  .left-menus {
    user-select: none;
    background-color: #FFF;
    overflow: auto;
    height: 100%;
    width: 182px;
    &::-webkit-scrollbar { width: 0 !important }
  }
  ul, li {padding: 0;margin: 0;}
  li {list-style: none;}

  .level-1 {
    .level-1-content {
      cursor: pointer;
      height: 80px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .level-1-name {
      transition: all .3s;
      font-size: 18px;
    }
    .level-1-icon {
      transition: all .3s;
      font-size: 30px;
    }
    &.has-child {
      .level-1-content::after {
        transition: all .3s;
        top: 20px;
        right: 17px;
        font-size: 20px;
        content: '...';
        color: #969AB8;
        position: absolute;
        writing-mode: vertical-lr;
      }
      .level-1-content::before {
        transition: all .3s;
        bottom: 0;
        left: 50%;
        font-family: 'iconfont';
        content: '\e656';
        transform-origin: center center;
        transform: translateX(-50%);
        color: #FFF;
        position: absolute;
        opacity: 0;
      }
    }
    &.active {
      .level-1-content::after {
        opacity: 0;
      }
      .level-1-content::before {
        opacity: 1;
      }
      .sub {
        display: flex;
      }
    }
  }

  .sub {
    display: none;
    flex-direction: column;
    box-sizing: border-box;
    .level-2 {
      position: relative;
      display: flex;
      cursor: pointer;
      justify-content: center;
    }
    .level-2.active {
      .level-2-name {
        &::before {
          opacity: 1;
        }
      }
    }
    .level-2-name {
      padding: 0 5%;
      box-sizing: border-box;
      display: block;
      text-align: left;
      color: #FFF;
      font-size: 16px;
      line-height: 40px;
      position: relative;
      &::before {
        font-family: 'iconfont';
        content: '\eca1';
        color: #FFF;
        opacity: 0;
        margin-right: 5px;
        transition: all .3s;
      }
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
</style>

<style lang="less">
  .pink-theme {
    .left-menus {
      .level-1 {
        .level-1-content:hover {
          .level-1-name, .level-1-icon {
            color: #F4628F;
          }
        }
        .level-1-name, .level-1-icon {
          color: #707070;
        }
        &.active {
          .level-1-content {
            background: #F4628F;
            &:hover {
              .level-1-name, .level-1-icon {
                color: #FFF;
              }    
            }
          }
          .level-1-name, .level-1-icon {
            color: #FFF;
          }
        }
      }
      .sub {
        background-color: #FBBAC4;
      }
    }
  }
  .blue-theme, .dark-theme {
    .left-menus {
      .level-1 {
        .level-1-content:hover {
          .level-1-name, .level-1-icon {
            color: #1728c7;
          }
        }
        .level-1-name, .level-1-icon {
          color: #6B75CA;
        }
        &.active {
          .level-1-content {
            background: #6B75CA;
          }
          .level-1-content:hover {
            .level-1-name, .level-1-icon {
              color: #FFF;
            }
          }
          .level-1-name, .level-1-icon {
            color: #FFF;
          }
        }
      }
      .sub {
        background-color: rgba(107, 117, 202, .6);
      }
    }
  }
  .yellow-theme {
    .left-menus {
      background-image: url('/static/images/yellow-left.png');
      background-size: 100% 100%;
      .sub .level-2-name, .level-1.has-child .level-1-content::after, .level-1-icon, .level-1-name {
        color: #6C573C;
      }
      .level-1.has-child.active {
        .level-1-content {
          background-color: #6C573C;
        }
        .level-1-icon, .level-1-name {
          color: #FFF;
        }
        .level-2.active {
          background-color: rgba(108, 87, 60, .6);
          .level-2-name {
            color: #FFF;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="left-menus">
    <ul class="main">
      <li class="level-1" :class="{
        'has-child': menu.children && menu.children.length,
        'active': activeMenuCode && activeMenuCode.startsWith(menu.pathCode) && (menu.pathCode === openLeftMenuCode)}" v-for="(menu, i) in menus" :key="menu.name + i">
        <div class="level-1-content" @click="clickMenu({menu, open: true})" :data-name="menu.name">
          <i class="iconfont level-1-icon" :class="{[menu.icon || 'icon-app']: true}"></i>
          <span class="level-1-name">{{ menu.name }}</span>
        </div>
        <ul class="sub">
          <li class="level-2" @click="clickMenu({menu: subMenu, goChild: true})" :class="{'active': activeMenuCode && activeMenuCode.startsWith(subMenu.pathCode)}" v-for="(subMenu, l) in menu.children" :key="subMenu.name + l">
            <span class="level-2-name">{{subMenu.name}}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LeftMenus',
  computed: {
    activeMenuCode () {
      return this.$store.getters.activeMenuCode
    },
    menus () {
      let headMenu = this.$store.getters.currentMenus.find(v => this.activeMenuCode && this.activeMenuCode.startsWith(v.pathCode))
      return headMenu && headMenu.children ? headMenu.children : []
    },
    openLeftMenuCode: {
      get () {
        return this.$store.getters.openLeftMenuCode
      },
      set (value) {
        return this.$store.dispatch('setOpenLeftMenuCode', value)
      }
    }
  },
  methods: {
    clickMenu (parm) {
      if (parm.open && this.openLeftMenuCode === parm.menu.pathCode) {
        this.openLeftMenuCode = null
      } else {
        this.$store.dispatch('setCurrentMenu', parm)
      }
    }
  }
}
</script>