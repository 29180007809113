<template>
  <div class="menu-list">
    <div class="menu-list-c scrollbar smallbar">
      <div class="menu-list-item" @click="change(item)" :class="{'menu-list-item-a': activeMenuCode && activeMenuCode.startsWith(item.pathCode)}" v-for="item in currentMenus" :key="item.id">
        {{item.name}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMenu',
  computed: {
    currentMenus () {
      return this.$store.getters.currentMenus
    },
    activeMenuCode () {
      return this.$store.getters.activeMenuCode
    }
  },
  methods: {
    change (item) {
      this.$store.dispatch('setCurrentMenu', {
        menu: item,
        goChild: true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.menu-list {
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .menu-list-c {
    max-width: 100%;
    overflow-x: auto;
    display: flex;
  }
  .menu-list-item {
    cursor: pointer;
    white-space:nowrap;
    padding: 0 10px 8px 10px;
    margin: 0 10px;
    font-size: 18px;
    text-align: center;
    border-bottom: 2px solid rgba(0,0,0,0);
  }
}
.pink-theme {
  .menu-list {
    .menu-list-item {
      color: #fff;
    }
    .menu-list-item:hover {
      color: #fff;
      border-color: #fff;
    }
    .menu-list-item-a {
      color: #fff;
      border-color: #F4628F;
    }
  }
}
.blue-theme, .dark-theme {
  .menu-list {
    .menu-list-item {
      color: #fff;
    }
    .menu-list-item:hover {
      border-color: #fff;
    }
    .menu-list-item-a {
      border-color: #fff;
    }
  }
}
.yellow-theme {
  .menu-list {
    .menu-list-item {
      color: #6C573C;
    }
    .menu-list-item:hover {
      border-color: #6C573C;
    }
    .menu-list-item-a {
      border-color: #6C573C;
    }
  }
}
</style>
