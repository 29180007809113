<template>
  <div class="the-heading">
    <router-link :to="$store.getters.clientType === 'web' ? {name: 'home'} : {name: 'wap.menus'}" tag="a" class="the-heading-left" ref="headLeft">
      <!-- <embed src="/static/logo/eloop-white.gif" v-if="theme === 'white'" height="70%" type="image/svg+xml"/> -->
      <!-- <img src="/static/logo/eloop-white.gif" v-if="theme === 'white'" height="100%" type="image/svg+xml"/>
      <img src="/static/logo/eloop-dark-new.gif" v-else-if="theme === 'dark'" height="100%" type="image/svg+xml"/>
      <img src="/static/logo/eloop.gif" v-else height="100%" type="image/svg+xml"/> -->
      <img src="/static/logo/blue-logo.png" v-if="theme === 'drak'" height="50%" style="width: 182px;object-fit: contain;"/>
      <img src="/static/logo/yellow-logo.png" v-else-if="theme === 'yellow'" height="50%" style="width: 182px;object-fit: contain;"/>
      <img src="/static/logo/pink-logo.png" v-else height="50%" style="width: 182px;object-fit: contain;"/>
    </router-link>
    <!-- warp利用position: relative使得hover展开的菜单刚好填充满右侧 -->
    <div class="menu-child-warp">
      <div class="the-heading-center">
        <the-menu></the-menu>
      </div>
      <div class="the-heading-right" ref="headRight">
        <the-user></the-user>
        <span v-if="hasApp" class="app-btn" @click="status.app = true">APP</span>
        <theme-switch></theme-switch>
      </div>
    </div>
    <Drawer v-model="status.app" width="700">
      <iframe v-if="status.app" src="/#/install/app" border style="width: 100%;height: calc(100% - 6px);border: none;" />
    </Drawer>
  </div>
</template>

<script>
import TheUser from './TheUser'
import ThemeSwitch from './ThemeSwitch'
import TheMenu from './TheMenu'
import env from '@/env/env'

export default {
  name: 'TheHeading',
  components: {
    TheUser,
    ThemeSwitch,
    TheMenu
  },
  data () {
    return {
      status: {
        app: false
      }
    }
  },
  props: {
    noMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasApp () {
      return env.app && env.app.merchantId && env.app.appId
    },
    theme () {
      return this.$store.getters.theme
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
@import '../../styles/theme.less';
.the-heading {
  height: @the-heading-height;
  min-height: @the-heading-height;
  display: flex;
  z-index: 30;
}
.the-heading-left {
  height: 100%;
  display: flex;
  align-items: center;
  // padding-right: 2rem;
  // .logo {
  //   display: block;
  //   width: 91px;
  //   height: 38px;
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   background-size: 100%;
  // }
}
.the-heading-center {
  flex: 1;
  height: 100%;
  width: 0;
}
.the-heading-right {
  height: 100%;
  display: flex;
  padding-right: 15px;
  align-items: center;
}

.menu-child-warp {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
}
.app-btn {
  cursor: pointer;
  border-radius: 15px;
  color: #657180;
  font-weight: 800;
  font-size: 12px;
  background-color: #FFF;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFF;
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    top: -30px;
    left: 50%;
    font-family: 'iconfont';
    content: '\e6bd';
    z-index: 1;
    transition: all .3s;
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    opacity: 0;
    transform: translateX(-50%) rotate(180deg);
  }
  &:hover::after {
    color: #FFF;
    background-color: #2d8cf0;
    top: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

// 主题
// .default-theme .logo {
//   background-image: url(/static/logo/eloop.svg);
// }
// .white-theme .logo {
//   background-image: url(/static/logo/eloop-white.svg);
// }
// .dark-theme .logo {
//   background-image: url(/static/logo/eloop-dark.svg);
// }

.white-theme {
  .the-heading {
    background-color: @color-background-white;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .2);
  }
}
.dark-theme {
  .the-heading {
    background-color: @color-background-dark;
  }
}
.pink-theme {
  .the-heading {
    background-image: url('/static/images/pink-top.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: @color-background-pink;
  }
}
.blue-theme {
  .the-heading {
    // background-image: url('/static/blue-theme/images/top.png');
    // background-size: 45% 100%;
    // background-repeat: no-repeat;
    // background-position: center right;
    // background-color: #FFF;
    background: #6B75CA;
  }
}
.yellow-theme {
  .the-heading {
    background-image: url('/static/images/yellow-top.png');
    background-size: 100% 100%;
  }
}
</style>
